import { Observable, catchError, map, of, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from '../../services/logger.service';
import { ConsumerPortalApiService } from '../../services/consumer-portal-api.service';
import { PaymentData, PaymentMinMaxBoundary, PaymentResponse } from '../models/payment-data.model';
import { DepotResponseData } from '../models/depot-config.interface';
@Injectable({
    providedIn: 'root'
})
export class PorPayService {
    constructor(private readonly logger: LoggerService, private readonly consumerPortalApi: ConsumerPortalApiService) {}

    loadScriptForPOR() {
        this.logger.logWarning('Will Load Script Here');
    }

    createPayment(
        /* eslint-disable @typescript-eslint/naming-convention */
        /**
         * Note camelCase : DB Model
         */
        CustomerGuid: string,
        CurrencyCode: string,
        Amount_Requested: number,
        OrganizationId: string,
        RemoteDepotId: string,
        contractId: string,
        amountBeingPaidBeforeFee: number
    ): Observable<PaymentResponse> {
        return this.consumerPortalApi
            .post<PaymentResponse>({
                controller: `payment/${CustomerGuid}/create-transaction`,
                method: 'POST',
                body: {
                    CustomerGuid: String(CustomerGuid),
                    Amount_Requested: Number(Amount_Requested),
                    CurrencyCode: String(CurrencyCode),
                    RemoteDepotId: String(RemoteDepotId),
                    contractId,
                    amountBeingPaidBeforeFee
                },
                headers: {
                    'x-organization-id': OrganizationId
                }
            })
            .pipe(
                tap({
                    error: (err: HttpErrorResponse) => {
                        this.logger.logWarning(err?.message);
                        this.logger.alertDevError(err);
                        return err;
                    },
                    next: (response: PaymentResponse) => {
                        this.logger.logInfo(response);
                        return response?.data;
                    }
                })
            );
    }

    verifyPayment(customerId: string, paymentData: PaymentData): Observable<PaymentResponse> {
        return this.consumerPortalApi
            .post<PaymentResponse>({
                controller: `payment/${customerId}/verify-transaction`,
                method: 'POST',
                body: paymentData,
                headers: {
                    'x-organization-id': paymentData.organizationId ?? ''
                }
            })
            .pipe(
                tap({
                    error: (err: HttpErrorResponse) => {
                        return err;
                    },
                    next: (response: PaymentResponse) => {
                        return response?.data;
                    }
                })
            );
    }

    getMinMaxFromPaymentBoundary(customerId: string, contractId: string): Observable<PaymentMinMaxBoundary[]> {
        return this.consumerPortalApi.get<PaymentMinMaxBoundary>(`payment/${customerId}/get-boundary/${contractId}`).pipe(
            tap({
                next: (data: PaymentMinMaxBoundary[]) => {
                    return data;
                },
                error: (error: HttpErrorResponse) => {
                    return error;
                }
            })
        );
    }

    /**
     * Get the depot config
     * @param {string} customerId
     * @param {string} depotId
     * @returns DepotResponseData[]
     */
    getDepotConfig(customerId: string, depotId: string): Observable<DepotResponseData[]> {
        return this.consumerPortalApi.get<DepotResponseData>(`payment/${customerId}/depot-config/${depotId}`).pipe(
            tap({
                next: (data: DepotResponseData[]) => {
                    return data;
                },
                error: (error: HttpErrorResponse) => {
                    return error;
                }
            })
        );
    }

    /**
     * Hide Make Payment button based on depot config, no depot config, hide and if consumer facing
     * is not available then also hide
     * @param {string} customerId
     * @param {string} depotId
     * @returns {Observable<boolean>} true/false
     */
    isDepotConfigAvailable(customerId: string, depotId: string): Observable<boolean> {
        return this.getDepotConfig(customerId, depotId).pipe(
            map((configs: DepotResponseData[]) => {
                const data: DepotResponseData = configs as unknown as DepotResponseData;
                if (data.total === 0) {
                    return false;
                }
                const hasCustomerFacing: boolean = data.results.some(depotConfig => {
                    const creditCardValue: string | null = depotConfig.CreditCardCustomerFacing;
                    const achValue: string | null = depotConfig.ACH;
                    return (creditCardValue !== null && creditCardValue.trim() !== '') || (achValue !== null && achValue.trim() !== '');
                });
                return hasCustomerFacing;
            }),
            catchError((error: HttpErrorResponse) => {
                this.logger.logError(error, '', true);
                return of(false);
            })
        );
    }
}
