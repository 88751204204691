import { Store } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { noRecordFoundItemsOut, itemsOutLoading, loadItemOuts } from '../store/actions/items-out-action.actions';
import { LoggerService } from '../services/logger.service';
import { ConsumerPortalApiService } from './consumer-portal-api.service';
import isEmpty from 'lodash-es/isEmpty';
import { FilterOutput } from '../shared/models/filters.model';
import { DatePipe } from '@angular/common';
import { CallOffRequestResponse, ItemOutCP } from '../models/item-out-model';
import { FeatureToggleService } from './feature-toggle.service';
import { ReturnRequestType } from '../enums/return-request-type.enum';
import { DateUtility } from '../date.utility';
import { DateFormatType } from '../enums/date-format-type.enum';

@Injectable({
    providedIn: 'root'
})
export class ItemOutService {
    dateFormat: string;

    constructor(
        private readonly store: Store,
        private readonly logger: LoggerService,
        private readonly consumerPortalApi: ConsumerPortalApiService,
        private readonly datePipe: DatePipe,
        private readonly orgFeatureService: FeatureToggleService,
        @Inject(LOCALE_ID) locale: string
    ) {
        this.dateFormat = DateUtility.getDateDisplayFormat(DateFormatType.StandardDate, locale);
    }

    getItemsOut({
        customerId,
        pageNumber,
        startDate,
        endDate,
        search,
        status
    }: {
        customerId: number | string;
        pageNumber?: number | undefined;
        status?: string | undefined;
        startDate?: string | undefined;
        endDate?: string | undefined;
        search?: string;
    }): Observable<unknown> {
        this.store.dispatch(noRecordFoundItemsOut({ loading: false }));
        this.store.dispatch(itemsOutLoading({ loading: true }));
        const finalFilter = [];
        /*
         *Text Search fields for My Rentals
         *contract #
         *line item name
         *serial #
         */
        if (search && !isEmpty(search)) {
            if (this.orgFeatureService.isAvailable('textSearchMultipleFilters')) {
                finalFilter.push({
                    field: '',
                    type: 'OR',
                    value: [
                        { field: 'Identifiers.ParentId', type: 'LIKE', value: search },
                        { field: 'Name', type: 'LIKE', value: search },
                        { field: 'SerialNumber', type: 'LIKE', value: search }
                    ]
                });
            }
        }

        /* Then Working on Date Filters */
        if (startDate && endDate) {
            finalFilter.push({ field: 'StartDateTime', type: '>=', value: new Date(new Date(startDate).setUTCHours(0, 0, 0, 0)) });
            finalFilter.push({ field: 'EndDateTime', type: '<=', value: new Date(new Date(endDate).setUTCHours(23, 59, 59, 999)) });
        }

        if (startDate && !endDate) {
            finalFilter.push({ field: 'StartDateTime', type: '>=', value: new Date(new Date(startDate).setUTCHours(0, 0, 0, 0)) });
        }
        if (endDate && !startDate) {
            finalFilter.push({ field: 'EndDateTime', type: '<=', value: new Date(new Date(endDate).setUTCHours(23, 59, 59, 999)) });
        }
        if (status) {
            finalFilter.push({ field: 'Status', type: '==', value: status });
        }

        let headers = {};
        if (finalFilter.length > 0) {
            headers = {
                /* eslint-disable @typescript-eslint/naming-convention */
                /**
                 * Note camelCase : DB Model
                 */
                'x-filter': JSON.stringify(finalFilter)
            };
        }
        /*
      End Adding Filters
    */

        return this.consumerPortalApi
            .get<ItemOutCP>(this.generateUrlforItemOut(customerId), {
                headers: {
                    ...headers,

                    'x-paging': JSON.stringify({
                        page: pageNumber,
                        pageSize: 10
                    })
                }
            })
            .pipe(
                tap({
                    next: (itemsOut: ItemOutCP[]) => {
                        if (!itemsOut) {
                            this.store.dispatch(itemsOutLoading({ loading: false }));
                            this.store.dispatch(noRecordFoundItemsOut({ loading: true }));
                        }
                        const items: ItemOutCP[] = [];
                        if (itemsOut?.length > 0) {
                            itemsOut.map((finalItem: ItemOutCP) => {
                                const itemName = finalItem?.itemName?.replace('(', ' (');
                                items.push({ ...finalItem, itemName });
                            });
                        }

                        this.store.dispatch(loadItemOuts({ items }));
                        this.store.dispatch(itemsOutLoading({ loading: false }));
                        if (itemsOut.length === 0) {
                            this.store.dispatch(noRecordFoundItemsOut({ loading: true }));
                        }
                    },
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    error: (e: HttpErrorResponse) => {
                        this.store.dispatch(itemsOutLoading({ loading: false }));
                        this.store.dispatch(noRecordFoundItemsOut({ loading: true }));
                    }
                })
            );
    }

    generateUrlforItemOut(customerId: number | string): string {
        return `customer/${customerId}/contracts/itemsout`;
    }

    calloffrent({
        items,
        customerId,
        customerName,
        accountName,
        isSecure,
        customerPhone,
        email,
        jobSiteName,
        jobSitePhone,
        requestedDate,
        requestedTime,
        gateCombo,
        address,
        comments,
        returnType
    }: {
        items: ItemOutCP[];
        customerId: string;
        customerName?: string | null;
        accountName?: string;
        isSecure?: string | null;
        customerPhone?: string | null;
        email?: string | null;
        jobSiteName?: string | null;
        jobSitePhone?: string | null;
        requestedDate?: string | null;
        requestedTime?: string | null;
        gateCombo?: string | null;
        address?: string | null;
        comments?: string | null;
        returnType: ReturnRequestType;
    }): Observable<CallOffRequestResponse> {
        const itemsData = items.map((item: ItemOutCP) => {
            return {
                itemId: item.id,
                itemName: item.itemName,
                contractId: item.contractId,
                serialNumber: item.serialNumber,
                contractDate: this.datePipe.transform(item.startDate, this.dateFormat) as string
            };
        });
        let formattedDate = '';
        let formattedTime = '';
        if (requestedDate) {
            formattedDate = this.datePipe.transform(requestedDate, this.dateFormat) as string;
        }
        if (requestedTime) {
            const dateParts = requestedTime.split(':');
            const date = new Date();
            date.setHours(+dateParts[0], +dateParts[1]);

            formattedTime = this.datePipe.transform(date, 'HH:mm') as string;
        }

        const params = {
            controller: `customer/${customerId}/calloffrent`,
            method: 'POST',
            body: {
                itemsData,
                isSecure,
                customerId,
                name: customerName,
                customerName,
                phone: customerPhone,
                email,
                date: requestedDate,
                time: requestedTime,
                jobSiteName,
                jobSitePhone,
                gateCombo,
                address,
                comments: comments ? comments : '',
                returnType,
                accountName,
                formattedDate,
                formattedTime
            }
        };
        return this.consumerPortalApi.post<CallOffRequestResponse>({ ...params }).pipe(
            tap({
                next: (res: CallOffRequestResponse) => {
                    return res;
                },
                error: (err: HttpErrorResponse) => {
                    this.logger.logError(err);
                    this.logger.alertDevError(err);
                    return of(true);
                }
            })
        );
    }

    filter($e: FilterOutput, customerId: string, pageNumber: number) {
        const startDate: string | undefined = !isEmpty($e.dates.startDate) ? (this.datePipe.transform($e.dates.startDate as string, 'Y-MM-dd') as string) : undefined;

        const endDate: string | undefined = !isEmpty($e.dates.endDate) ? (this.datePipe.transform($e.dates.endDate as string, 'Y-MM-dd') as string) : undefined;

        return this.getItemsOut({
            customerId,
            pageNumber,
            startDate: startDate,
            endDate: endDate,
            search: $e.search,
            status: $e.singleFilter
        });
    }
}
