import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Output } from '@angular/core';
import { DateUtility } from '../../../date.utility';
import { DateFormatType } from '../../../enums/date-format-type.enum';
import { FormBuilder, Validators } from '@angular/forms';
import { AppFormValidators } from '../../../admin/validators/app.validators';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, take, tap } from 'rxjs';
import { Customer } from '../../../models/consumer';
import { Store, select } from '@ngrx/store';
import { getCustomer } from '../../../store/selector/customer.selectors';
import { ContractDetail, LineItems } from '../../../models/contract-model';
import { AuthUiService } from '../../../services/auth-ui.service';
import { NotificationService } from '../../../services';
import { ContractService } from '../../../services/contract.service';
import { CloneContract, ItemsData } from '../../../models/clone-contract.model';
import { DatePipe } from '@angular/common';
import { AppFacadeService } from '../../../services/app-facade.service';
import { AppMediatorService } from '../../../services/app-mediator.service';

@Component({
    selector: 'por-book-again-contract-request-form',
    templateUrl: './book-again-contract-request-form.component.html',
    styleUrls: ['./book-again-contract-request-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookAgainContractRequestFormComponent implements OnInit, OnDestroy {
    dateFormat: string;
    isStepValid = false;
    minDate = new Date(); // Set it to the current date to disable past dates.
    displayedRowCount = 10;

    @Input() uiUrl: string | undefined;

    @Output()
    readonly isFormReturn: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    readonly isFormSubmittedSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();

    cloneContractForm = this.fb.group({
        customerName: ['', [Validators.required, Validators.maxLength(255)]],
        customerPhone: ['', [Validators.required, Validators.maxLength(30)]],
        email: ['', [Validators.required, AppFormValidators.emailValidator, Validators.maxLength(255)]],
        startDate: ['', [Validators.required, AppFormValidators.futureDateValidator]],
        startTime: ['', [Validators.required]],
        modifications: ['', [Validators.nullValidator]]
    });

    customer$!: Observable<Customer>;
    @Input() customerId!: string;
    subscriptions: Subscription[] = [];
    customerInfo!: Customer | null;
    @Input() contractDetail!: ContractDetail[] | null;

    constructor(
        @Inject(LOCALE_ID) locale: string,
        private readonly appMediatorService: AppMediatorService,
        private readonly translateService: TranslateService,
        private readonly store: Store,
        private readonly auth: AuthUiService,
        private readonly appFacadeService: AppFacadeService,
        private readonly notification: NotificationService,
        private readonly fb: FormBuilder,
        private readonly contractService: ContractService,
        private readonly datePipe: DatePipe
    ) {
        this.dateFormat = DateUtility.getDateDisplayFormat(DateFormatType.StandardDate, locale);
    }

    ngOnInit(): void {
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);

        this.cloneContractForm.setValue({
            customerName: '',
            customerPhone: '',
            email: '',
            startDate: '',
            startTime: '',
            modifications: ''
        });
        this.customer$ = this.store.pipe(select(getCustomer));

        this.subscriptions.push(
            this.customer$
                .pipe(
                    take(1),
                    tap(customer => {
                        this.customerInfo = customer;
                    })
                )
                .subscribe()
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.map(sub => sub.unsubscribe());
    }

    get customerEmail() {
        return this.auth.user.Email;
    }

    get customerName() {
        return this.cloneContractForm.get('customerName');
    }

    get customerPhone() {
        return this.cloneContractForm.get('customerPhone');
    }

    get email() {
        return this.cloneContractForm.get('email');
    }

    get startDate() {
        return this.cloneContractForm.get('startDate');
    }

    get startTime() {
        return this.cloneContractForm.get('startTime');
    }

    get modifications() {
        return this.cloneContractForm.get('modifications');
    }

    return() {
        this.isFormReturn.emit(true);
    }

    isValidContractDateTime() {
        if (this.startDate?.valid && this.startTime?.valid) {
            return true;
        }
        return false;
    }

    sendCloneContractForm() {
        this.appFacadeService.setLoading(true);
        if (!this.cloneContractForm.valid) {
            this.notification.error(this.translateService.instant('pleaseFillRequired'));
            this.appFacadeService.setLoading(false);
            return false;
        }

        if (!this.customerId) {
            return;
        }
        const itemsData: ItemsData[] | undefined = this.contractDetail?.[0].LineItems.map((item: LineItems) => {
            return {
                itemId: item?.ProductId ?? item?.StockId,
                itemName: item?.Name,
                quantity: item?.Quantity
            };
        });
        let startDate = '',
            startTime = '';

        if (this.cloneContractForm.value?.startDate) {
            startDate = this.datePipe.transform(this.cloneContractForm.value?.startDate, this.dateFormat) as string;
        }
        if (this.cloneContractForm.value?.startTime) {
            const starttime = this.cloneContractForm.value.startTime;
            const dateParts = starttime.split(':');

            if (dateParts.length === 2) {
                const date = new Date();
                date.setHours(+dateParts[0], +dateParts[1], 0, 0); // set hours, minutes, and reset seconds/milliseconds to zero

                startTime = this.datePipe.transform(date, 'HH:mm') as string;
            }
        }
        const data: CloneContract = {
            items: itemsData,
            contractId: this.contractDetail?.[0]?.Id,
            customerId: this.customerId,
            accountName: this.customerInfo?.CompanyName ?? this.customerInfo?.Name,
            ...this.cloneContractForm.value,
            startTime,
            startDate
        };
        this.subscriptions.push(
            this.contractService.cloneContract(data).subscribe({
                next: () => {
                    this.appFacadeService.setLoading(false);
                    this.notification.success(this.translateService.instant('CloneContractFormSuccess'));
                    this.cloneContractForm.reset();
                    this.isFormSubmittedSuccess.emit(true);
                },
                error: () => {
                    this.appFacadeService.setLoading(false);
                    this.notification.error(this.translateService.instant('somethingWentWrong'));
                }
            })
        );
        return;
    }

    showLoadMoreButton() {
        return this.contractDetail?.[0]?.LineItems?.length !== undefined && this.contractDetail?.[0]?.LineItems?.length > this.displayedRowCount;
    }
}
